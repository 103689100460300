import React from "react"
import { Link } from "gatsby"

const Anchor = ({ children, to, activeClassName, partiallyActive, ...other }) => {
	const url = 'https://cms.checkthatproperty.com.au/api'.replace("wp/api", "")

	to = to.replace(url, "/")

	const current = /^#/.test(to)
	const internal = /^\/(?!\/)/.test(to)

	if (current || internal) {
		return (
			<Link to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
				{children}
			</Link>
		)
	}

	return (
		<a href={to} {...other} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	)
}
export default Anchor
