import React from "react"

import { cartInitialState, cartReducer, useLocalStorageReducer } from "./reducer"

export const CartContext = React.createContext([cartInitialState, () => {}])

export function CartProvider({ children }) {
	const [cart, dispatch] = useLocalStorageReducer("cart", cartReducer, cartInitialState)

	const contextValue = React.useMemo(
		() => [
			cart,
			action => {
				dispatch(action)
			},
		],
		[cart, dispatch]
	)

	return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useShoppingCart = () => {
	const [cart, dispatch] = React.useContext(CartContext)

	const addItem = product => dispatch({ type: "add", product })
	const removeItem = product => dispatch({ type: "remove", product })
	const clearItems = () => dispatch({ type: "clear" })

	return {
		...cart,
		addItem,
		removeItem,
		clearItems,
	}
}
