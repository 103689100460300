import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"

import { CartProvider } from "../../utilities/context"

import Anchor from "../components/anchor"
import Icons from "../partials/icons"
import Header from "./header"

const Sites = loadable(() => import("../partials/sites"))
const Footer = loadable(() => import("./footer"))

const Backdrop = styled.div`
	${tw`fixed inset-0 hidden`}

	.offcanvas-active & {
		${tw`block lg:hidden z-10`}
	}
`

const MessageBar = tw.div`hidden bg-granny text-center py-3`

const Layout = ({ children, className }) => {
	const {
		wp: {
			generalSettings: { title },
		},
	} = useStaticQuery(graphql`
		query SiteQuery {
			wp {
				generalSettings {
					title
				}
			}
		}
	`)

	return (
		<CartProvider>
			<Icons></Icons>
			<MessageBar id="system-status">
				<div className="container wider"></div>
			</MessageBar>
			<Anchor className="screen-reader-text" to="#site-content">
				Skip to content
			</Anchor>
			<Header title={title}></Header>

			<div id="site-content" className={className}>
				{children}
			</div>

			<Sites isSearch={"isSearch" === className}></Sites>
			<Footer title={title}></Footer>
			<Backdrop onClick={() => document.body.classList.remove("offcanvas-active")}></Backdrop>
		</CartProvider>
	)
}

export default Layout
