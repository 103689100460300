import React from "react"

const Icons = () => {
	return (
		<svg width="0" height="0" style={{
			position: 'absolute',
			width: 0,
			height: 0,
			overflow: 'hidden'
		}}>
			<defs>
				<symbol id="spinner" viewBox="0 0 512 512">
					<path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
				</symbol>

				<symbol id="shopping-cart" viewBox="0 0 576 512">
					<path fill="currentColor" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path>
				</symbol>

				<symbol id="chevron-down" viewBox="0 0 448 512">
					<path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
				</symbol>

				<symbol id="arrow-down" viewBox="0 0 448 512">
					<path fill="currentColor" d="M441.9 250.1l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L250 385.4V44c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v341.4L42.9 230.3c-4.7-4.7-12.3-4.7-17 0L6.1 250.1c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
				</symbol>

				<symbol id="arrow-right" viewBox="0 0 448 512">
					<path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path>
				</symbol>

				<symbol id="facebook" viewBox="0 0 448 512">
					<path fill="currentColor" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
				</symbol>

				<symbol id="linkedin" viewBox="0 0 448 512">
					<path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
				</symbol>

				<symbol id="search" viewBox="0 0 512 512">
					<path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path>
				</symbol>

				<symbol id="lightbulb" viewBox="0 0 352 512">
					<path fill="currentColor" d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path>
				</symbol>

				<symbol id="bullseye" viewBox="0 0 496 512">
					<path fill="currentColor" d="M242.16 240.67L27.98 301.55c-15.17 4.31-16.95 25.1-2.73 31.92l68.47 32.89-89.17 89.17c-6.07 6.06-6.07 15.9 0 21.96l21.96 21.96c6.07 6.06 15.9 6.06 21.96 0l89.17-89.17 32.89 68.47c6.83 14.22 27.61 12.44 31.92-2.73l60.87-214.18c3.68-12.91-8.25-24.83-21.16-21.17zm27.36 117.03l-14.08 49.55C335.92 403.3 400 337.46 400 256c0-84.02-68-152-152-152-81.47 0-147.3 64.1-151.25 144.57l49.55-14.08C156.25 187.44 198.04 152 248 152c57.35 0 104 46.65 104 104 0 49.96-35.44 91.75-82.48 101.7zM248 8C111.03 8 0 119.03 0 256c0 7.3.47 14.49 1.09 21.63 3.46-1.97 7-3.87 10.99-5l36.24-10.3c-.07-2.12-.32-4.19-.32-6.33 0-110.28 89.72-200 200-200s200 89.72 200 200-89.72 200-200 200c-2.14 0-4.21-.25-6.33-.32l-10.3 36.24c-1.14 4.02-3.15 7.5-5.14 10.98 7.19.63 14.42 1.1 21.77 1.1 136.97 0 248-111.03 248-248S384.97 8 248 8z"></path>
				</symbol>

				<symbol id="eye" viewBox="0 0 576 512">
					<path fill="currentColor" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
				</symbol>

				<symbol id="plus" viewBox="0 0 448 512">
					<path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
				</symbol>

				<symbol id="minus" viewBox="0 0 448 512">
					<path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
				</symbol>

				<symbol id="times" viewBox="0 0 352 512">
					<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
				</symbol>

				<symbol id="file-check" viewBox="0 0 384 512">
					<path fill="currentColor" d="M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 0 1 384 121.941zM248 160h136v328c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V24C0 10.745 10.745 0 24 0h200v136c0 13.2 10.8 24 24 24zm65.296 109.732l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L162.12 346.45l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068L70.772 328.2c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z"></path>
				</symbol>

				<symbol id="stars" viewBox="0 0 153 29">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-143.000000, -1817.000000)" fill="#FFD060">
							<g transform="translate(143.000000, 1817.000000)">
								<polygon points="14.5 21.75 5.97711384 26.2307464 7.60484026 16.7403732 0.709680514 10.0192536 10.2385569 8.63462679 14.5 0 18.7614431 8.63462679 28.2903195 10.0192536 21.3951597 16.7403732 23.0228862 26.2307464"></polygon>
								<polygon points="45.5 21.75 36.9771138 26.2307464 38.6048403 16.7403732 31.7096805 10.0192536 41.2385569 8.63462679 45.5 0 49.7614431 8.63462679 59.2903195 10.0192536 52.3951597 16.7403732 54.0228862 26.2307464"></polygon>
								<polygon points="76.5 21.75 67.9771138 26.2307464 69.6048403 16.7403732 62.7096805 10.0192536 72.2385569 8.63462679 76.5 0 80.7614431 8.63462679 90.2903195 10.0192536 83.3951597 16.7403732 85.0228862 26.2307464"></polygon>
								<polygon points="107.5 21.75 98.9771138 26.2307464 100.60484 16.7403732 93.7096805 10.0192536 103.238557 8.63462679 107.5 0 111.761443 8.63462679 121.290319 10.0192536 114.39516 16.7403732 116.022886 26.2307464"></polygon>
								<polygon points="138.5 21.75 129.977114 26.2307464 131.60484 16.7403732 124.709681 10.0192536 134.238557 8.63462679 138.5 0 142.761443 8.63462679 152.290319 10.0192536 145.39516 16.7403732 147.022886 26.2307464"></polygon>
							</g>
						</g>
					</g>
				</symbol>

				<symbol id="double-up" viewBox="0 0 34 43">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-173.000000, -3125.000000)" fill="currentColor">
							<g transform="translate(173.000000, 3125.000000)">
								<path d="M33.2340187,37.6776695 L28.2842712,42.627417 C27.893747,43.0179413 27.260582,43.0179413 26.8700577,42.627417 L17.6776695,33.4350288 C17.2871452,33.0445046 16.6539803,33.0445046 16.263456,33.4350288 L7.07106781,42.627417 C6.68054352,43.0179413 6.04737854,43.0179413 5.65685425,42.627417 L0.707106781,37.6776695 C0.316582489,37.2871452 0.316582489,36.6539803 0.707106781,36.263456 L16.263456,20.7071068 C16.6539803,20.3165825 17.2871452,20.3165825 17.6776695,20.7071068 L33.2340187,36.263456 C33.624543,36.6539803 33.624543,37.2871452 33.2340187,37.6776695 Z"></path>
								<path d="M33.2340187,17.6776695 L28.2842712,22.627417 C27.893747,23.0179413 27.260582,23.0179413 26.8700577,22.627417 L17.6776695,13.4350288 C17.2871452,13.0445046 16.6539803,13.0445046 16.263456,13.4350288 L7.07106781,22.627417 C6.68054352,23.0179413 6.04737854,23.0179413 5.65685425,22.627417 L0.707106781,17.6776695 C0.316582489,17.2871452 0.316582489,16.6539803 0.707106781,16.263456 L16.263456,0.707106781 C16.6539803,0.316582489 17.2871452,0.316582489 17.6776695,0.707106781 L33.2340187,16.263456 C33.624543,16.6539803 33.624543,17.2871452 33.2340187,17.6776695 Z"></path>
							</g>
						</g>
					</g>
				</symbol>

				<symbol id="double-down" viewBox="0 0 34 43">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-160.000000, -415.000000)" fill="currentColor">
							<g transform="translate(160.029437, 415.029437)">
								<path d="M33.2340187,17.6776695 L28.2842712,22.627417 C27.893747,23.0179413 27.260582,23.0179413 26.8700577,22.627417 L17.6776695,13.4350288 C17.2871452,13.0445046 16.6539803,13.0445046 16.263456,13.4350288 L7.07106781,22.627417 C6.68054352,23.0179413 6.04737854,23.0179413 5.65685425,22.627417 L0.707106781,17.6776695 C0.316582489,17.2871452 0.316582489,16.6539803 0.707106781,16.263456 L16.263456,0.707106781 C16.6539803,0.316582489 17.2871452,0.316582489 17.6776695,0.707106781 L33.2340187,16.263456 C33.624543,16.6539803 33.624543,17.2871452 33.2340187,17.6776695 Z" transform="translate(16.970563, 11.667262) rotate(-180.000000) translate(-16.970563, -11.667262)"></path>
								<path d="M33.2340187,37.6776695 L28.2842712,42.627417 C27.893747,43.0179413 27.260582,43.0179413 26.8700577,42.627417 L17.6776695,33.4350288 C17.2871452,33.0445046 16.6539803,33.0445046 16.263456,33.4350288 L7.07106781,42.627417 C6.68054352,43.0179413 6.04737854,43.0179413 5.65685425,42.627417 L0.707106781,37.6776695 C0.316582489,37.2871452 0.316582489,36.6539803 0.707106781,36.263456 L16.263456,20.7071068 C16.6539803,20.3165825 17.2871452,20.3165825 17.6776695,20.7071068 L33.2340187,36.263456 C33.624543,36.6539803 33.624543,37.2871452 33.2340187,37.6776695 Z" transform="translate(16.970563, 31.667262) rotate(-180.000000) translate(-16.970563, -31.667262)"></path>
							</g>
						</g>
					</g>
				</symbol>

				<symbol id="speech-quote" viewBox="0 0 45 35">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-1008.000000, -1864.000000)" fill="#F85547" fillRule="nonzero">
							<g transform="translate(1008.840000, 1864.280000)">
								<path d="M20,34 L20,18.1724138 L11.9277108,18.1724138 L11.9277108,16.6482759 C11.9277108,11.0988506 14.0562249,8.32413793 18.313253,8.32413793 L18.313253,8.32413793 L18.313253,0 C12.2891566,0 7.73092369,1.77816092 4.63855422,5.33448276 C1.54618474,8.8908046 0,13.5218391 0,19.2275862 C0,24.1517241 0.923694779,29.0758621 2.77108434,34 L2.77108434,34 L20,34 Z M44,34 L44,18.1724138 L35.8072289,18.1724138 L35.8072289,16.6482759 C35.8072289,11.0988506 37.9759036,8.32413793 42.313253,8.32413793 L42.313253,8.32413793 L42.313253,0 C36.2891566,0 31.7309237,1.77816092 28.6385542,5.33448276 C25.5461847,8.8908046 24,13.5218391 24,19.2275862 C24,24.3862069 24.9236948,29.3103448 26.7710843,34 L26.7710843,34 L44,34 Z"></path>
							</g>
						</g>
					</g>
				</symbol>

				<symbol id="tag-blue" viewBox="0 0 50 46">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-167.000000, -797.000000)" fill="#E4E9F1">
							<g transform="translate(166.999900, 797.658400)">
								<path d="M46.723,1.42108547e-14 L13.727,1.42108547e-14 C12.327,1.42108547e-14 11.21,1.006 10.926,2.322 C10.881,2.79 10.865,3.286 10.805,3.74 C10.328,7.349 9.379,10.517 7.932,13.22 C5.889,17.028 3.246,20.041 2.16715534e-13,22.259 C3.246,24.476 5.889,27.489 7.932,31.298 C9.379,34 10.328,37.168 10.805,40.777 C10.865,41.232 10.881,41.728 10.926,42.196 C11.21,43.512 12.327,44.518 13.727,44.518 L46.723,44.518 C48.337,44.518 49.645,43.209 49.645,41.596 L49.645,2.922 C49.645,1.309 48.337,1.42108547e-14 46.723,1.42108547e-14" transform="translate(24.822500, 22.259000) rotate(-180.000000) translate(-24.822500, -22.259000) "></path>
							</g>
						</g>
					</g>
				</symbol>

				<symbol id="tag-current" viewBox="0 0 50 46">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-167.000000, -797.000000)" fill="currentColor">
							<g transform="translate(166.999900, 797.658400)">
								<path d="M46.723,1.42108547e-14 L13.727,1.42108547e-14 C12.327,1.42108547e-14 11.21,1.006 10.926,2.322 C10.881,2.79 10.865,3.286 10.805,3.74 C10.328,7.349 9.379,10.517 7.932,13.22 C5.889,17.028 3.246,20.041 2.16715534e-13,22.259 C3.246,24.476 5.889,27.489 7.932,31.298 C9.379,34 10.328,37.168 10.805,40.777 C10.865,41.232 10.881,41.728 10.926,42.196 C11.21,43.512 12.327,44.518 13.727,44.518 L46.723,44.518 C48.337,44.518 49.645,43.209 49.645,41.596 L49.645,2.922 C49.645,1.309 48.337,1.42108547e-14 46.723,1.42108547e-14" transform="translate(24.822500, 22.259000) translate(-24.822500, -22.259000) "></path>
							</g>
						</g>
					</g>
				</symbol>

				<symbol id="tag-tick" viewBox="0 0 50 46">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-167.000000, -797.000000)" fill="currentColor">
							<g transform="translate(166.999900, 797.658400)">
								<path d="M46.723,1.42108547e-14 L13.727,1.42108547e-14 C12.327,1.42108547e-14 11.21,1.006 10.926,2.322 C10.881,2.79 10.865,3.286 10.805,3.74 C10.328,7.349 9.379,10.517 7.932,13.22 C5.889,17.028 3.246,20.041 2.16715534e-13,22.259 C3.246,24.476 5.889,27.489 7.932,31.298 C9.379,34 10.328,37.168 10.805,40.777 C10.865,41.232 10.881,41.728 10.926,42.196 C11.21,43.512 12.327,44.518 13.727,44.518 L46.723,44.518 C48.337,44.518 49.645,43.209 49.645,41.596 L49.645,2.922 C49.645,1.309 48.337,1.42108547e-14 46.723,1.42108547e-14" transform="translate(24.822500, 22.259000) translate(-24.822500, -22.259000) "></path>
							</g>
						</g>
					</g>
					<polygon fill="#35507C" points="44.5217 18.8104 39.3357 12.2744 25.3637 23.3574 20.9247 17.7614 14.3887 22.9454 21.8757 32.3854 24.0107 35.0774"></polygon>
				</symbol>
			</defs>
		</svg>
	);
}

export default Icons
