import React from "react"
import tw, { styled } from "twin.macro"

import Anchor from "./anchor"

const Wrapper = styled(Anchor)`
	${tw`
		inline-block py-3 px-6 md:px-12
		border-2 rounded-full
		text-xl font-medium text-center
		no-underline cursor-pointer
		bg-sunset border-sunset text-white

		hover:(bg-watermelon text-white) focus:(bg-watermelon text-white rounded-none)

		disabled:opacity-40 disabled:cursor-not-allowed
	`}
	${({ isAlt }) => isAlt && tw`bg-sanjuan border-sanjuan hover:bg-kashmir focus:bg-kashmir`};
`

const Button = ({ children, myRef, ...other }) => {
	return (
		<Wrapper as={!other?.to && !other?.href ? "button" : ""} ref={myRef} {...other}>
			{children}
		</Wrapper>
	)
}

export default Button
