import { useStorageReducer } from "react-storage-hooks"

export const cartInitialState = {
	itemDetails: {},
	itemCount: 0,
	totalTax: 0,
	totalBase: 0,
	totalPrice: 0,
	docusign: false,
}

export const formatPriceString = value => {
	if ("string" !== typeof value) {
		value = value.toFixed(2)
	}

	return `$${value} AUD`
}

function extract(array, column) {
	return Object.values(array).map(item => item[column])
}

function reducer(total, value) {
	return total + parseFloat(value)
}

export function cartReducer(state, action) {
	function calculate(items, column) {
		return extract(items, column).reduce(reducer, 0).toFixed(2)
	}

	function hasVic(items) {
		const vics = Object.values(items).filter(item => "VIC" === item.state)

		return vics.length ? true : false
	}

	function add(product) {
		const itemDetails = {
			...state.itemDetails,
			[product.id]: product,
		}

		return {
			itemDetails,
			itemCount: state.itemCount + 1,
			totalTax: calculate(itemDetails, "tax"),
			totalBase: calculate(itemDetails, "base"),
			totalPrice: calculate(itemDetails, "price"),
			docusign: hasVic(itemDetails),
		}
	}

	function remove(product) {
		const itemDetails = state.itemDetails
		const itemCount = state.itemCount - 1

		delete itemDetails[product.id]

		const totalTax = calculate(itemDetails, "tax")
		const totalBase = calculate(itemDetails, "base")
		const totalPrice = calculate(itemDetails, "price")
		const docusign = hasVic(itemDetails)

		return { itemDetails, itemCount, totalTax, totalBase, totalPrice, docusign }
	}

	if ("add" === action.type && !(action.product.id in state.itemDetails)) {
		return add(action.product)
	} else if ("remove" === action.type && action.product.id in state.itemDetails) {
		return remove(action.product)
	} else if ("clear" === action.type && state.itemDetails) {
		state = cartInitialState
	}

	return state
}

export function useLocalStorageReducer(key, reducer, initialState) {
	const dummyStorage = {
		getItem() {
			return null
		},
		setItem() {},
		removeItem() {},
	}

	return useStorageReducer(
		typeof window === "object" ? window.localStorage : dummyStorage,
		key,
		reducer,
		initialState
	)
}
