import React from "react"
import tw, { styled } from "twin.macro"

import { filter, uniqBy } from "lodash"
import { graphql, useStaticQuery } from "gatsby"
import { useShoppingCart } from "../../utilities/context"

import Anchor from "../components/anchor"
import SVG from "../components/svg"

const Wrapper = styled.nav`
	${({ isHeader }) => isHeader && tw`hidden w-full z-20 lg:(block w-auto)`};

	ul {
		${tw`-mx-4 flex flex-wrap justify-center overflow-hidden`};
	}

	li {
		${tw`py-2 px-4 md:px-6`};
	}

	a {
		${tw`font-semibold text-xl pt-1 border-b-4 border-transparent`};
		${tw`text-white hover:border-white`};
		${({ isHeader }) => isHeader && tw`text-sanjuan hover:border-sanjuan`};
	}

	.active {
		${tw`border-white`};
		${({ isHeader }) => isHeader && tw`text-sanjuan border-sanjuan`};
	}
`

const Cart = styled(Anchor)`
	&& {
		${tw`relative pb-1 text-sanjuan`};
	}

	svg {
		${tw`mr-1`};
	}

	span {
		${tw`absolute -top-1 -right-1 bg-granny w-5 h-5 rounded-full text-xs flex flex-wrap items-center justify-center`};
	}
`

const Navbar = ({ isHeader, className }) => {
	const { allWpMenuItem, allWpPage } = useStaticQuery(graphql`
		query NavbarQuery {
			allWpMenuItem(sort: { fields: order, order: ASC }) {
				nodes {
					id
					path
					label
					locations
					connectedNode {
						node {
							... on WpPost {
								id
							}
						}
					}
				}
			}
			allWpPage(filter: { isPostsPage: { eq: true } }) {
				nodes {
					id
					uri
				}
			}
		}
	`)
	const items = filter(allWpMenuItem.nodes, node =>
		node.locations.includes(isHeader ? "GATSBY_HEADER_MENU" : "GATSBY_FOOTER_MENU")
	)
	const blogUri = allWpPage.nodes[0]?.uri.replace(/\/$/, "") ?? ""
	const { itemCount } = useShoppingCart()

	className += isHeader ? " site-menu" : ""

	return (
		<Wrapper className={className} isHeader={isHeader}>
			<ul>
				{items.length &&
					uniqBy(items, "label").map(node => {
						return (
							<li key={node.id}>
								<Anchor
									to={`${node.connectedNode?.node?.id ? blogUri : ""}${node.path}`}
									activeClassName="active"
									onClick={() => document.body.classList.remove("offcanvas-active")}
								>
									{node.label}
								</Anchor>
							</li>
						)
					})}

				{isHeader && (
					<li>
						<Cart to="/checkout" activeClassName="active">
							<SVG width="32" height="28" title="Checkout" id="shopping-cart" />
							<React.Fragment>{!!itemCount && <span>{itemCount}</span>}</React.Fragment>
						</Cart>
					</li>
				)}
			</ul>
		</Wrapper>
	)
}

export default Navbar
