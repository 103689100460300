import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import tw from "twin.macro"

import Navbar from "./navbar"
import Anchor from "../components/anchor"
import Button from "../components/button"

const Wrapper = tw.header`py-4`
const Toggle = tw(Button)`flex flex-wrap items-center px-3 rounded-lg sm:rounded-full sm:px-6 z-30 lg:hidden`
const Label = tw.span`mr-3! sr-only sm:not-sr-only`
const Bars = tw.span`w-8 block -my-1`
const Bar = tw.span`h-1 block bg-white my-1 rounded-sm`

const Header = ({ title }) => {
	const [activeOffcanvas, setActiveOffcanvas] = React.useState(false)

	const toggleOffcanvas = e => {
		let active = true

		if (!activeOffcanvas || !document.body.classList.contains("offcanvas-active")) {
			active = false

			document.body.classList.add("offcanvas-active")
		} else {
			document.body.classList.remove("offcanvas-active")
		}

		e.currentTarget.setAttribute("aria-expanded", !active)

		setActiveOffcanvas(!active)
	}

	const {
		file: {
			childImageSharp: { fixed: LogoMono },
		},
	} = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "CTP-Logo-224x64.png" }) {
				childImageSharp {
					fixed(width: 224, height: 64) {
						...GatsbyImageSharpFixed_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Wrapper className="site-header">
			<div className="container wider" tw="flex flex-wrap items-center justify-between">
				<Anchor to="/" style={{ maxWidth: "224px" }}>
					<Image fixed={LogoMono} alt={title} />
				</Anchor>

				<Toggle onClick={toggleOffcanvas} aria-expanded="false">
					<Label>Menu</Label>
					<Bars>
						<Bar></Bar>
						<Bar></Bar>
						<Bar></Bar>
					</Bars>
				</Toggle>
				<Navbar isHeader></Navbar>
			</div>
		</Wrapper>
	)
}

export default Header
